export enum LoadersId {
  VERIFY_ACCOUNT = 'VERIFY_ACCOUNT',
  IS_LOGIN = 'IS_LOGIN',
  IS_SIGNUP = 'IS_SIGNUP',
  IS_FETCHING_AUTH = 'IS_FETCHING_AUTH',
  IS_SEARCHING_NFTS = 'IS_SEARCHING_NFTS',
  IS_FETCHING_NFT_LIST = 'IS_FETCHING_NFT_LIST',
  IS_FETCHING_COLLECTION_LIST = 'IS_FETCHING_COLLECTION_LIST',
  IS_FETCHING_COLLECTION_NFTS_LIST = 'IS_FETCHING_COLLECTION_NFTS_LIST',
  IS_FETCHING_EVENT_LIST = 'IS_FETCHING_EVENT_LIST',
  IS_FETCHING_REWARDS = 'IS_FETCHING_REWARDS',
  IS_DOWNLOADING_REWARDS_CSV = 'IS_DOWNLOADING_REWARDS_CSV',
  IS_FETCHING_GIFT_LIST = 'IS_FETCHING_GIFT_LIST',
  IS_VALIDATE_SIGNING_MESSAGE = 'IS_VALIDATE_SIGNING_MESSAGE',
  IS_RESENDING_EMAIL_VERIFY = 'IS_RESENDING_EMAIL_VERIFY',
  MOBILE_LOGIN = 'MOBILE_LOGIN',
  IS_SENDING_NFT = 'IS_SENDING_NFT',
  IS_SENDING_MULTIPLE_NFTS = 'IS_SENDING_MULTIPLE_NFTS',
  FETCH_COLLECTION_BY_ID = 'FETCH_COLLECTION_BY_ID',
  FETCH_COLLECTION_BY_NAME = 'FETCH_COLLECTION_BY_NAME',
  IS_EDITING_COLLECTION = 'IS_EDITING_COLLECTION',
  IS_EDITING_NFT = 'IS_EDITING_NFT',
  IS_CREATING_COLLECTION = 'IS_CREATING_COLLECTION',
  IS_CREATING_NFT = 'IS_CREATING_NFT',
  IS_DELETING_COLLECTION = 'IS_DELETING_COLLECTION',
  IS_DELETING_NFT = 'IS_DELETING_NFT',
  IS_PUBLISHING_COLLECTION = 'IS_PUBLISHING_COLLECTION',
  IS_CREATING_GIFT = 'IS_CREATING_GIFT',
  IS_EDITING_GIFT = 'IS_EDITING_GIFT',
  IS_DELETING_GIFT = 'IS_DELETING_GIFT',
  IS_PUBLISHING_GIFT = 'IS_PUBLISHING_GIFT',
  IS_CLAIMING_COLLECTION_NFT = 'IS_CLAIMING_COLLECTION_NFT',
  IS_CLAIMING_QUEST_COLLECTION_NFT = 'IS_CLAIMING_QUEST_COLLECTION_NFT',
  IS_SENDING_REQUEST_RESET_PASSWORD = 'IS_SENDING_REQUEST_RESET_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  IS_SENDING_CONTACT_SALES = 'IS_SENDING_CONTACT_SALES',
  IS_SENDING_COLLECTION_INFO = 'IS_SENDING_COLLECTION_INFO',
  IS_CREATE_OR_EDIT_NFT = 'IS_CREATE_OR_EDIT_NFT',
  IS_CREATE_OR_EDIT_REWARD = 'IS_CREATE_OR_EDIT_REWARD',
  IS_CREATE_OR_EDIT_QUEST = 'IS_CREATE_OR_EDIT_QUEST',
  IS_DOWNLOADING_ACTIVITY_DATA = 'IS_DOWNLOADING_ACTIVITY_DATA',
  IS_IMPORTING_NFT = 'IS_IMPORTING_NFT',
  IS_LOGING_OFF = 'IS_LOGING_OFF',
  FETCH_QUEST_TYPES = 'FETCH_QUEST_TYPES',
  FETCH_QUESTS = 'FETCH_QUESTS',
  IS_DELETING_QUEST = 'IS_DELETING_QUEST',
  FETCH_USER_QUESTS = 'FETCH_USER_QUESTS',
  FETCH_USER_COLLECTION_NFTS = 'FETCH_USER_COLLECTION_NFTS',
  IS_COMPLETING_QUEST = 'IS_COMPLETING_QUEST',
  IS_LOADING_EDIT_USER = 'IS_LOADING_EDIT_USER'
}
