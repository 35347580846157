// Actions
import {ILoaderState, LoaderActions, LoadersActionsTypes} from './types';
import {LoadersId} from '@type/loaders';

const initialLoaderState: ILoaderState = {
  [LoadersId.FETCH_COLLECTION_BY_ID]: false,
  [LoadersId.FETCH_COLLECTION_BY_NAME]: false,
  [LoadersId.IS_CREATING_COLLECTION]: false,
  [LoadersId.IS_CREATING_NFT]: false,
  [LoadersId.IS_DELETING_COLLECTION]: false,
  [LoadersId.IS_DELETING_NFT]: false,
  [LoadersId.IS_EDITING_COLLECTION]: false,
  [LoadersId.IS_EDITING_NFT]: false,
  [LoadersId.IS_SEARCHING_NFTS]: false,
  [LoadersId.IS_FETCHING_AUTH]: true,
  [LoadersId.IS_FETCHING_COLLECTION_LIST]: true,
  [LoadersId.IS_FETCHING_COLLECTION_NFTS_LIST]: false,
  [LoadersId.IS_FETCHING_EVENT_LIST]: false,
  [LoadersId.IS_FETCHING_GIFT_LIST]: false,
  [LoadersId.IS_FETCHING_NFT_LIST]: false,
  [LoadersId.IS_FETCHING_REWARDS]: false,
  [LoadersId.IS_DOWNLOADING_REWARDS_CSV]: false,
  [LoadersId.IS_LOGIN]: false,
  [LoadersId.IS_PUBLISHING_COLLECTION]: false,
  [LoadersId.IS_RESENDING_EMAIL_VERIFY]: false,
  [LoadersId.IS_SENDING_NFT]: false,
  [LoadersId.IS_SENDING_MULTIPLE_NFTS]: false,
  [LoadersId.IS_SIGNUP]: false,
  [LoadersId.IS_VALIDATE_SIGNING_MESSAGE]: false,
  [LoadersId.MOBILE_LOGIN]: false,
  [LoadersId.VERIFY_ACCOUNT]: false,
  [LoadersId.IS_CREATING_GIFT]: false,
  [LoadersId.IS_EDITING_GIFT]: false,
  [LoadersId.IS_DELETING_GIFT]: false,
  [LoadersId.IS_PUBLISHING_GIFT]: false,
  [LoadersId.IS_CLAIMING_COLLECTION_NFT]: false,
  [LoadersId.IS_CLAIMING_QUEST_COLLECTION_NFT]: false,
  [LoadersId.IS_SENDING_REQUEST_RESET_PASSWORD]: false,
  [LoadersId.RESET_PASSWORD]: false,
  [LoadersId.IS_SENDING_CONTACT_SALES]: false,
  [LoadersId.IS_CREATE_OR_EDIT_NFT]: false,
  [LoadersId.IS_CREATE_OR_EDIT_REWARD]: false,
  [LoadersId.IS_CREATE_OR_EDIT_QUEST]: false,
  [LoadersId.IS_DOWNLOADING_ACTIVITY_DATA]: false,
  [LoadersId.IS_IMPORTING_NFT]: false,
  [LoadersId.IS_LOGING_OFF]: false,
  [LoadersId.FETCH_QUESTS]: false,
  [LoadersId.FETCH_QUEST_TYPES]: false,
  [LoadersId.IS_DELETING_QUEST]: false,
  [LoadersId.FETCH_USER_QUESTS]: false,
  [LoadersId.FETCH_USER_COLLECTION_NFTS]: false,
  [LoadersId.IS_COMPLETING_QUEST]: false,
  [LoadersId.IS_LOADING_EDIT_USER]: false,
  [LoadersId.IS_SENDING_COLLECTION_INFO]: false
};

function loaderReducer(state: ILoaderState = initialLoaderState, action: LoadersActionsTypes): ILoaderState {
  switch (action.type) {
    case LoaderActions.SET_IS_LOADING:
      return {
        ...state,
        [action.payload.id]: action.payload.isLoading
      };

    default:
      return state;
  }
}

export default loaderReducer;
