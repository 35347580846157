// Dependencies
import React from 'react';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';

// Main
import AppLayout from '@layouts/AppLayout';
import Home from '@pages/main/Home';
import MyCollections from 'src/pages/user/MyCollections';
import MyNFTs from 'src/pages/user/MyNFTs';
import MyRewards from 'src/pages/user/MyRewards';
import PrivacyPolicy from '@pages/main/PrivacyPolicy';
import UseTerms from '@pages/main/UseTerms';
import NFTClaimTerms from '@pages/main/NFTClaimTerms';

// Auth
import LogIn from '@pages/auth/LogIn';
import SignIn from '@pages/auth/SignIn';
import VerifyAccount from '@pages/auth/VerifyAccount';
import MobileLogin from '@pages/auth/MobileLogin';
import ResetPassword from '@pages/auth/ResetPassword';

// Details
import NFTDetails from 'src/pages/user/NFTDetails';
import CollectionDetails from 'src/pages/user/CollectionDetails';

// Creator
import CreatorDetails from '@pages/creator/CreatorDetails';
import NewCollection from '@pages/creator/NewCollection';
import CollectionPreview from '@pages/creator/CollectionPreview';
import EditCollection from '@pages/creator/EditCollection';

// Test Components
import TestComponents from '@pages/test-components';

// Components
import Web3Manager from '@managers/Web3Manager';
import TwitterCallbackQuest from '@components/quests/TwitterCallbackQuest';
import DiscordCallbackQuest from '@components/quests/DiscordCallbackQuest';
import InstagramCallbackQuest from '@components/quests/InstagramCallbackQuest';
import YouTubeCallbackQuest from '@components/quests/YouTubeCallbackQuest';
import TwitchCallbackQuest from '@components/quests/TwitchCallbackQuest';
import TikTokCallbackQuest from '@components/quests/TikTokCallbackQuest';

// Hooks
import useAuth from '@hooks/useAuth';

// Types
import {IUser} from '@type/user';

export const createRouter = (user: IUser | null | undefined) => (
  createBrowserRouter([
    {
      path: '*',
      element: ''
    },
    {
      path: '/login',
      element: <AppLayout><LogIn /></AppLayout>
    },
    {
      path: '/sign-in',
      element: <AppLayout><SignIn /></AppLayout>
    },
    {
      path: '/mobile/:token',
      element: <MobileLogin />
    },
    {
      path: '/privacy-policy',
      element: <AppLayout><PrivacyPolicy /></AppLayout>
    },
    {
      path: '/use-terms',
      element: <AppLayout><UseTerms /></AppLayout>
    },
    {
      path: '/nft-claim-terms',
      element: <AppLayout><NFTClaimTerms /></AppLayout>
    },
    {
      path: '/collection/:name',
      element: <AppLayout><CollectionDetails /></AppLayout>
    },
    {
      path: '/test-components',
      element: <TestComponents />
    },
    {
      path: '/quests/twitter-callback',
      element: <AppLayout><TwitterCallbackQuest /></AppLayout>
    },
    {
      path: '/quests/discord-callback',
      element: <AppLayout><DiscordCallbackQuest /></AppLayout>
    },
    {
      path: '/quests/instagram-callback',
      element: <AppLayout><InstagramCallbackQuest /></AppLayout>
    },
    {
      path: '/quests/google-callback',
      element: <AppLayout><YouTubeCallbackQuest /></AppLayout>
    },
    {
      path: '/quests/twitch-callback',
      element: <AppLayout><TwitchCallbackQuest /></AppLayout>
    },
    {
      path: '/quests/tiktok-callback',
      element: <AppLayout><TikTokCallbackQuest /></AppLayout>
    },
    ...(user ? [
      {
        path: '/',
        element: <AppLayout><MyNFTs /></AppLayout>
      },
      {
        path: '/rewards',
        element: <AppLayout><MyRewards /></AppLayout>
      },
      {
        path: '/collections',
        element: <AppLayout><MyCollections /></AppLayout>
      },
      {
        path: '/nft/:id',
        element: <AppLayout><NFTDetails /></AppLayout>
      },
      ...(user.creator ? [
        {
          path: '/creator',
          element: <AppLayout><CreatorDetails /></AppLayout>
        },
        {
          path: '/creator/new-collection',
          element: <AppLayout><NewCollection /></AppLayout>
        },
        {
          path: '/creator/collection/:id',
          element: <AppLayout><CollectionPreview /></AppLayout>
        },
        {
          path: '/creator/collection/:id/edit',
          element: <AppLayout><EditCollection /></AppLayout>
        }
      ] : [])
    ] : [
      {
        path: '/',
        element: <AppLayout><Home /></AppLayout>
      },
      {
        path: '/verify/:token',
        element: <AppLayout><VerifyAccount /></AppLayout>
      },
      {
        path: '/resetpassword/:token',
        element: <AppLayout><ResetPassword /></AppLayout>
      }
    ])
  ])
);

function Navigator(): React.ReactElement | null {
  const {user} = useAuth();
  const router = createRouter(user);

  return (
    <Web3Manager>
      <RouterProvider router={router} />
    </Web3Manager>
  );
}

export default Navigator;
