// Dependencies
import React, {useCallback} from 'react';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import Badge from '@components/Badge';

// Hooks
import useRewards from '@hooks/useRewards';

// Services
import {generateGiftApplePass} from '@services/gift';

// Utils
import {formatDate} from '@utils/date';

// Styles
import styles from './GiftCard.module.scss';

// Types
import {GiftCardProps} from './types';

import language_es from 'src/locales/es/pages/user/giftDetails.json';
import language_en from 'src/locales/en/pages/user/giftDetails.json';

function GiftCard({canEdit, canViewReward, onClickEdit, onClickDelete, onClickPublish, ...gift}: GiftCardProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {fetchRewards} = useRewards(gift.id);

  const getRewardMessage = (rewardTypeId: number) => {
    switch (rewardTypeId) {
      case 1:
        return {
          type: 'Code',
          rewardMessage: language.getYourCode,
          icon: ''
        };
      case 2:
        return {
          type: 'Link',
          rewardMessage: language.getTheLink,
          icon: ''
        };
      case 3:
        return {
          type: 'Multimedia',
          rewardMessage: language.enjoyTheMultimedia,
          icon: ''
        };
      case 4:
        return {
          type: 'Lottery',
          rewardMessage: language.getLotteryResults,
          icon: ''
        };
      default:
        return {
          type: '',
          rewardMessage: '',
          icon: ''
        };
    }
  };

  const reward = getRewardMessage(gift.rewardTypeId);

  const onGetRewardsClick = useCallback(() => {
    fetchRewards('user', gift.id);
  }, []);

  const onGenerateApplePassClick = useCallback(() => {
    generateGiftApplePass(gift.id)
      .then(blob => {
        console.log(blob);
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pkpass'); // You can set a default file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <div className={styles.layout}>
      <div className={styles.field}>
        <div className={styles.title}>
          <Typography variant={'display'} size={'xs'} weight={'bold'}>{gift.title}</Typography>
        </div>
        <div className={styles.title}>
          <Typography variant={'text'} size={'xs'}>Available from: {formatDate(gift.date)}</Typography>
        </div>
      </div>
      <div className={styles.field}>
        <div className={styles.picture}>
          <img alt={gift.title} src={gift.image} width={'100%'} />
        </div>

        {!gift.published ? (
          <div className={styles.badge}>
            <Badge
              color={'blue'}
              variant={'solid'}
              size={'sm'}
              label={'Draft'}
            />
          </div>
        ) : (
          <div className={styles.badge}>
            <Badge
              color={'blue'}
              variant={'outline'}
              size={'sm'}
              label={'New'}
              className={styles.badgeInner}
            />
          </div>
        )}

      </div>
      {canViewReward && (
        <div className={styles.row}>
          <Button
            size={'md'}
            variant={'solid'}
            onClick={onGetRewardsClick}
            color={'primary'}>
            {reward.rewardMessage}
          </Button>

          <Button
            size={'md'}
            variant={'solid'}
            onClick={onGenerateApplePassClick}
            color={'primary'}>
            Generate Apple Pass
          </Button>

        </div>
      )}

      {canEdit && (<div className={styles.inline}>
        <Button size={'md'} onClick={onClickEdit}>{language.edit}</Button>
        <Button size={'md'} onClick={onClickDelete} variant={'solid'} color={'error'}>{language.delete}</Button>
        <Button size={'md'} onClick={onClickPublish} variant={'solid'} color={'tertiary'}>{language.publish}</Button>
      </div>)}
    </div>
  );
}
export default GiftCard;
