import {IGift} from '@type/gift';
import {OptionItem} from '@components/Selector';
import {ItemPhoto} from '@forms/CreateOrEditRewardForm/types';

export enum giftActionsTypes {
  FETCH_GIFT_LIST = 'FETCH_GIFT_LIST',
  FETCH_GIFT_BY_ID = 'FETCH_GIFT_BY_ID',
  FETCH_GIFT_LIST_BY_COLLECTION_ID = 'FETCH_GIFT_LIST_BY_COLLECTION_ID',
  SET_GIFT_LIST = 'SET_GIFT_LIST',
  SET_GIFT_LIST_COLLECTION = 'SET_GIFT_LIST_COLLECTION',
  CREATE_GIFT = 'CREATE_GIFT',
  EDIT_GIFT = 'EDIT_GIFT',
  DELETE_GIFT = 'DELETE_GIFT',
  PUBLISH_GIFT = 'PUBLISH_GIFT',
  SET_GIFT_DATA = 'SET_GIFT_DATA'
}

export interface IGiftState {
  list?: {[key: string]: IGift};
  collectionList?: {[key: string]: IGift}
}

/**
 * @interface FetchGiftListAction
 * @description Represents an action to fetch a list of gifts.
 *
 * @property {string} type - The type of the action, which should be FETCH_GIFT_LIST.
 * @property {Object} payload - The payload of the action.
 * @property {Boolean} payload.onlyPublished - A boolean indicating whether only published gifts should be fetched.
 */
export interface FetchGiftListAction {
  type: typeof giftActionsTypes.FETCH_GIFT_LIST;
  payload: {
    onlyPublished: Boolean
  }
}

/**
 * Represents an action to fetch a gift by its ID.
 * @interface FetchGiftByIdAction
 * @property {string} type - The action type.
 * @property {Object} payload - The action payload.
 * @property {number} payload.id - The ID of the gift to be fetched.
 */
export interface FetchGiftByIdAction {
  type: typeof giftActionsTypes.FETCH_GIFT_BY_ID;
  payload: {
    id: number
  }
}

/**
 * Represents an action to fetch the list of gifts by collection ID.
 * @interface FetchGiftListByCollectionIdAction
 * @property {string} type - The action type.
 * @property {Object} payload - The action payload.
 * @property {number} payload.collectionId - The ID of the collection for which to fetch the list of gifts.
 * @property {Boolean} payload.onlyPublished - Whether to fetch only the published gifts.
 */
export interface FetchGiftListByCollectionIdAction {
  type: typeof giftActionsTypes.FETCH_GIFT_LIST_BY_COLLECTION_ID;
  payload: {
    collectionId: number,
    onlyPublished: Boolean
  }
}

/**
 * Represents an action to set the list of gifts.
 * @interface SetGiftListAction
 * @property {string} type - The action type.
 * @property {Object} payload - The action payload.
 * @property {Object} [payload.list] - The list of gifts, indexed by key.
 */
export interface SetGiftListAction {
  type: typeof giftActionsTypes.SET_GIFT_LIST;
  payload: {
    list?: {[key: string]: IGift}
  }
}

/**
 * Represents an action to set the list of gifts for a collection.
 * @interface SetGiftListCollectionAction
 * @property {string} type - The action type.
 * @property {Object} payload - The action payload.
 * @property {Object} [payload.list] - The list of gifts for the collection, indexed by key.
 */
export interface SetGiftListCollectionAction {
  type: typeof giftActionsTypes.SET_GIFT_LIST_COLLECTION;
  payload: {
    list?: {[key: string]: IGift}
  }
}

/**
 * Represents the properties of a gift to be created.
 * @interface CreateGiftActionProps
 */
export interface CreateGiftActionProps {
  image?: ItemPhoto | string;
  title: string;
  description: string;
  type?: OptionItem;
  rewardContent: ItemPhoto | string;
  date: string;
  unique: boolean;
}

/**
 * Represents an action to create a new gift.
 * @interface CreateGiftAction
 * @property {string} type - The action type.
 * @property {Object} payload - The action payload.
 * @property {number} payload.collectionId - The ID of the collection to which the gift belongs.
 * @property {CreateGiftActionProps} payload.gift - The properties of the gift to be created.
 */
export interface CreateGiftAction {
  type: typeof giftActionsTypes.CREATE_GIFT
  payload: {
    collectionId: number,
    gift: CreateGiftActionProps
  }
}

/**
 * Represents the properties of a gift to be edited.
 * @interface EditGiftActionProps
 * @property {number} giftId - The ID of the gift to be edited.
 * @property {Object} data - The new properties of the gift.
 */
export interface EditGiftActionProps {
  giftId: number;
  data: {
    image?: ItemPhoto | string;
    title: string;
    description: string;
    type?: OptionItem;
    date: string;
    unique: boolean;
    rewardContent: ItemPhoto | string;
  }
}

/**
 * Represents an action to delete a gift.
 * @interface DeleteGiftAction
 * @property {string} type - The action type.
 * @property {Object} payload - The action payload.
 * @property {number} payload.giftId - The ID of the gift to be deleted.
 */
export interface EditGiftAction {
  type: typeof giftActionsTypes.EDIT_GIFT;
  payload: EditGiftActionProps;
}

/**
 * Represents an action to set the data of a gift.
 * @interface SetGiftDataAction
 * @property {string} type - The action type.
 * @property {Object} payload - The action payload.
 * @property {number} payload.giftId - The ID of the gift to be updated.
 * @property {IGift} payload.data - The new data of the gift.
 */
export interface DeleteGiftAction {
  type: typeof giftActionsTypes.DELETE_GIFT;
  payload: {
    collectionId: number;
    giftId: number;
  }
}

/**
 * Represents an action to publish a gift.
 * @interface PublishGiftAction
 * @property {string} type - The action type.
 * @property {Object} payload - The action payload.
 * @property {number} payload.giftId - The ID of the gift to be published.
 */
export interface PublishGiftAction {
  type: typeof giftActionsTypes.PUBLISH_GIFT;
  payload: {
    giftId: number;
  }
}

/**
 * Represents an action to set the data of a gift.
 * @interface SetGiftDataAction
 * @property {string} type - The action type.
 * @property {Object} payload - The action payload.
 * @property {number} payload.giftId - The ID of the gift to be updated.
 * @property {IGift} payload.data - The new data of the gift.
 */
export interface SetGiftDataAction {
  type: typeof giftActionsTypes.SET_GIFT_DATA;
  payload: {
    giftId: number;
    data: IGift;
  }
}

/**
 * Represents a union type of all possible gift actions.
 * @type GiftActions
 * @description A type representing any of the defined gift actions.
 */
export type GiftActions =
  FetchGiftListAction |
  FetchGiftByIdAction |
  SetGiftListAction |
  SetGiftListCollectionAction |
  CreateGiftAction |
  EditGiftAction |
  DeleteGiftAction |
  PublishGiftAction |
  SetGiftDataAction;
