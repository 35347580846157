// Dependencies
import React, {useCallback} from 'react';
import classNames from 'clsx';

// Types
import {CreateRewardProps} from './types';

// Hooks
import useGifts from '@hooks/useGifts';
import useLoader, {LoadersId} from '@hooks/useLoader';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './CreateOrEditRewardModal.module.scss';

// Components
import Typography from '@components/Typography';

// Forms
import CreateOrEditRewardForm, {FormValues} from '@forms/CreateOrEditRewardForm';

// Types
import {rewardTypes} from '@type/rewardType';

import language_es from 'src/locales/es/pages/creator/createOrEditReward.json';
import language_en from 'src/locales/en/pages/creator/createOrEditReward.json';

function CreateOrEditRewardModal({collectionId, reward}: CreateRewardProps): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {createGift, editGift} = useGifts();
  const {isLoading} = useLoader(LoadersId.IS_CREATE_OR_EDIT_REWARD);

  /**
   * Callback function to handle submitting the creation of a new NFT.
   *
   * @function
   * @name handleSubmitCreateGift
   * @param {Object} values - Form values object with properties: image {File}, name {string},
   * description {string}, copies {string}, supply {string}.
   * @returns {void}
   */
  const handleSubmitCreateGift = useCallback((values: FormValues) => {
    if (reward) {
      editGift(reward.id, {
        image: values.image,
        title: values.title,
        description: values.description,
        type: values.type,
        date: values.date,
        unique: values.unique,
        rewardContent: values.rewardContent
      });
    } else {
      if (values.image instanceof File) {
        createGift(collectionId, {
          image: values.image,
          title: values.title,
          description: values.description,
          type: values.type,
          date: values.date,
          unique: values.unique,
          rewardContent: values.rewardContent
        });
      }
    }
  }, [collectionId, reward]);

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={styles.row}>
        <Typography variant={'display'} size={'sm'} weight={'semiBold'}>{language.createReward}</Typography>
        <Typography>{language.createRewardDescription}</Typography>
      </div>
      <div className={styles.row}>
        <CreateOrEditRewardForm
          isLoading={isLoading}
          {...reward ? ({
            initialValues: {
              image: reward.image,
              title: reward.title,
              description: reward.description,
              type: {
                value: rewardTypes().filter(e => e.value === reward.rewardTypeId)[0].value,
                label: language[rewardTypes().filter(e => e.value === reward.rewardTypeId)[0].label as keyof typeof language]
              },
              rewardContent: reward.rewardContent as string,
              date: reward.date,
              unique: reward.unique !== undefined ? reward.unique : false
            }
          }) : ({
            initialValues: {
              image: undefined,
              title: '',
              description: '',
              type: {
                value: 0,
                label: ''
              },
              rewardContent: '',
              date: '',
              unique: false
            }
          })}
          onSubmit={handleSubmitCreateGift}
        />
      </div>
    </div>
  );
}

export default CreateOrEditRewardModal;
