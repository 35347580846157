import * as Yup from 'yup';

export const NewRewardStep1Validation = Yup.object().shape({
  image: Yup.mixed().required('This field is required'),
  title: Yup.string().required('The field is required'),
  description: Yup.string().required('The title is required'),
  date: Yup.date()
    .required('Date is required')
    .typeError('Invalid date format')
});

export const NewRewardStep2Validation = Yup.object().shape({
  unique: Yup.boolean()
});
