import React, {useEffect, useRef} from 'react';

function TelegramLoginWidget() {
  const telegramWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://telegram.org/js/telegram-widget.js?22';
    scriptElement.setAttribute('data-telegram-login', 'GivitNFTBot');
    scriptElement.setAttribute('data-size', 'large');
    scriptElement.setAttribute('data-auth-url', 'https://268c-213-37-18-74.ngrok-free.app/api/telegram-auth');
    scriptElement.async = true;

    telegramWrapperRef.current?.appendChild(scriptElement);
  }, []);

  return (
    <div ref={telegramWrapperRef}></div>
  );
}

export default TelegramLoginWidget;
