// Dependencies
import React, {useEffect} from 'react';
import {FormikProps, useFormik} from 'formik';

// Types
import {CreateQuestFormProps, FormValues} from './types';
import {QuestActionTypeEnum, QuestTypeEnum} from '@type/quest';

// Hooks
import useForm from '@hooks/useForm';
import useModal from '@hooks/useModal';
import useQuests from '@hooks/useQuests';

// Validation
import CreateQuestValidation from './validations';

// Components
import Typography from '@components/Typography';
import TextInput from '@components/TextInput';
import Selector from '@components/Selector';
import Button from '@components/Button';
import QuillTextInput from '@components/QuillTextInput';
import {Tweet} from 'react-tweet';

// StyleSheet
import styles from './CreateOrEditQuestForm.module.scss';

// Assets
import {FaDiscord, FaInstagram, FaTiktok, FaYoutube, FaTwitch, FaTelegram} from 'react-icons/fa';
import XIcon from '@assets/icons/x-logo-tw.svg';
import {RiExternalLinkLine} from 'react-icons/ri';
import Loader from '@components/Loader';

import language_es from 'src/locales/es/forms/createOrEditQuestForm.json';
import language_en from 'src/locales/en/forms/createOrEditQuestForm.json';

const initialDefaultValues: FormValues = {
  title: '',
  type: {
    label: '',
    value: 0,
    icon: ''
  },
  typeId: 0,
  actionType: {
    label: '',
    value: 0
  },
  actionTypeId: 0,
  description: '',
  handler: '',
  extraHandler: '',
  xp: 0
};

function CreateOrEditQuestForm({initialValues = initialDefaultValues, isLoading, onSubmit}: CreateQuestFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();
  const {questTypes, questActionTypes, fetchQuestTypes} = useQuests();

  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: CreateQuestValidation,
    onSubmit
  });

  const isEditing = initialValues !== initialDefaultValues;
  const {handleChangeField, getErrorFromField} = useForm<FormValues>(formik);

  useEffect(() => {
    fetchQuestTypes();
  }, []);

  const getHandlerName = () => {
    const actionTypeId = formik.values.actionTypeId;
    if (actionTypeId === 1) return 'Twitter account';
    if (actionTypeId === 2) return 'Twitter post link';
    if (actionTypeId === 3) return 'Twitter post link';
    if (actionTypeId === 4) return 'Twitter post link';
    if (actionTypeId === 5) return 'Twitter hashtag to include';
    if (actionTypeId === 6) return 'Twitter Spaces name';
    if (actionTypeId === 7) return 'Instagram account';
    if (actionTypeId === 8) return 'Discord invite code';
    if (actionTypeId === 9) return 'TikTok account';
    if (actionTypeId === 10) return 'YouTube channel name';
    if (actionTypeId === 11) return 'Twitch channel name';
    if (actionTypeId === 12) return language.telegramChannelNameLabel;

    return 'Handler';
  };

  const getPlaceholder = () => {
    const actionTypeId = formik.values.actionTypeId;
    if (actionTypeId === 1) return '@givitnft';
    if (actionTypeId === 2) return '1734411726286880963';
    if (actionTypeId === 3) return '1734411726286880963';
    if (actionTypeId === 4) return '1734411726286880963';
    if (actionTypeId === 5) return '#GivitNFT';
    if (actionTypeId === 6) return 'GivitNFT';
    if (actionTypeId === 7) return 'GivitNFT';
    if (actionTypeId === 8) return '47tFBFA8';
    if (actionTypeId === 9) return '#GivitNFT';
    if (actionTypeId === 10) return 'GivitNFT';
    if (actionTypeId === 11) return 'GivitNFT';
    if (actionTypeId === 12) return 'GivitNFT';

    return '';
  };

  const getIcon = (typeId: number) => {
    switch (typeId) {
      case QuestTypeEnum.Twitter:
        return <XIcon />;

      case QuestTypeEnum.Instagram:
        return <FaInstagram />;

      case QuestTypeEnum.Discord:
        return <FaDiscord />;

      case QuestTypeEnum.TikTok:
        return <FaTiktok />;

      case QuestTypeEnum.YouTube:
        return <FaYoutube />;

      case QuestTypeEnum.Twitch:
        return <FaTwitch />;

      case QuestTypeEnum.Telegram:
        return <FaTelegram />;

      default:
        return null;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent default form submission

    if (!isEditing) {
      switch (formik.values.actionTypeId) {
        case 1:
          handleChangeField('title', questActionTypes.find(actionType => actionType.id === 1)?.title?.replace('@', formik.values.handler));
          handleChangeField('description', questActionTypes.find(actionType => actionType.id === 1)?.description?.replace('@', formik.values.handler));
          break;
        case 2:
          handleChangeField('title', questActionTypes.find(actionType => actionType.id === 2)?.title);
          handleChangeField('description', questActionTypes.find(actionType => actionType.id === 2)?.description);
          break;
        case 3:
          handleChangeField('title', questActionTypes.find(actionType => actionType.id === 3)?.title);
          handleChangeField('description', questActionTypes.find(actionType => actionType.id === 3)?.description);
          break;
        case 4:
          handleChangeField('title', questActionTypes.find(actionType => actionType.id === 4)?.title);
          handleChangeField('description', questActionTypes.find(actionType => actionType.id === 4)?.description);
          break;
        case 5:
          handleChangeField('title', questActionTypes.find(actionType => actionType.id === 5)?.title);
          handleChangeField('description', questActionTypes.find(actionType => actionType.id === 5)?.description);
          break;
        case 6:
          handleChangeField('title', questActionTypes.find(actionType => actionType.id === 6)?.title);
          handleChangeField('description', questActionTypes.find(actionType => actionType.id === 6)?.description);
          break;
        case 7:
          handleChangeField('title', questActionTypes.find(actionType => actionType.id === 7)?.title?.replace('@', formik.values.handler));
          handleChangeField('description', questActionTypes.find(actionType => actionType.id === 7)?.description);
          break;
        case 8:
          handleChangeField('title', questActionTypes.find(actionType => actionType.id === 8)?.title?.replace('@', formik.values.extraHandler as string));
          handleChangeField('description', questActionTypes.find(actionType => actionType.id === 8)?.description);
          break;
        case 9:
          handleChangeField('title', questActionTypes.find(actionType => actionType.id === 9)?.title?.replace('@', formik.values.handler));
          handleChangeField('description', questActionTypes.find(actionType => actionType.id === 9)?.description);
          break;
        case 10:
          handleChangeField('title', questActionTypes.find(actionType => actionType.id === 10)?.title?.replace('@', formik.values.handler));
          handleChangeField('description', questActionTypes.find(actionType => actionType.id === 10)?.description);
          break;
        case 11:
          handleChangeField('title', questActionTypes.find(actionType => actionType.id === 11)?.title?.replace('@', formik.values.handler));
          handleChangeField('description', questActionTypes.find(actionType => actionType.id === 11)?.description);
          break;
        case 12:
          handleChangeField('title', questActionTypes.find(actionType => actionType.id === 12)?.title?.replace('@', formik.values.handler));
          handleChangeField('description', questActionTypes.find(actionType => actionType.id === 12)?.description);
          break;
      }
    }

    // Then, if everything is okay, submit the form
    formik.handleSubmit();
  };

  if (isLoading || !questTypes || !questActionTypes || questTypes.length === 0 || questActionTypes.length === 0) return <Loader />;

  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <div className={styles.field}>
        <Typography variant={'text'} size={'md'} component={'span'} weight={'regular'}>{language.selectSocialNetwork}</Typography>
        <Selector
          options={questTypes.map((questType) => ({
            value: questType.id,
            label: questType.name as string,
            icon: getIcon(questType.id)
          }))}
          disabled={isEditing}
          value={formik.values.type}
          onChange={(item) => {
            const questActionType = questActionTypes.filter((questActionType) => questActionType.questType?.id === item.value)[0];
            handleChangeField('type', item);

            if (questActionType) {
              const actionType = {
                value: questActionType.id,
                label: questActionType.name
              };

              handleChangeField('actionTypeId', questActionType.id);
              handleChangeField('actionType', actionType);
            }
          }}
        />
      </div>
      <div className={styles.field}>
        <Typography variant={'text'} size={'md'} component={'span'} weight={'regular'}>{language.selectQuestType}</Typography>
        <Selector
          options={questActionTypes.filter((questActionType) => Number(questActionType.questType?.id) === Number(formik.values.type?.value)).map((questActionType) => ({
            value: questActionType.id,
            label: questActionType.name as string
          }))}
          disabled={isEditing}
          value={formik.values.actionType}
          onChange={(item) => {
            handleChangeField('actionTypeId', Number(item.value));
            handleChangeField('actionType', item);
          }}
        />
      </div>

      {isEditing && (
        <>
          <div className={styles.field}>
            <Typography variant={'text'} size={'md'} component={'span'} weight={'regular'}>{language.titleLabel}</Typography>
            <TextInput
              name={'title'}
              placeholder={language.titlePlaceholder}
              value={formik.values.title}
              onChangeText={(name) => handleChangeField('title', name)}
              error={getErrorFromField('title')}
            />
          </div>

          <div className={styles.field}>
            <Typography variant={'text'} size={'md'} component={'span'} weight={'regular'}>{language.descriptionLabel}</Typography>
            <QuillTextInput
              className={styles.description}
              placeholder={language.descriptionPlaceholder}
              theme='snow'
              value={formik.values.description}
              onChange={(value) => handleChangeField('description', value === '<p><br></p>' ? '' : value)}
              error={getErrorFromField('description')}
            />
          </div>
        </>
      )}

      {formik.values.actionTypeId as number === QuestActionTypeEnum.JoinDiscord && (
        <div className={styles.field}>
          <TextInput
            name={'extraHandler'}
            label={language.discordServerNameLabel}
            placeholder={language.discordServerNamePlaceholder}
            value={formik.values.extraHandler}
            onChangeText={(extraHandler) => handleChangeField('extraHandler', extraHandler)}
            error={getErrorFromField('extraHandler')}
          />
        </div>
      )}

      {formik.values.actionTypeId as number > 0 && (
        <div className={styles.field}>
          <TextInput
            name={'handler'}
            label={getHandlerName()}
            placeholder={getPlaceholder()}
            value={formik.values.handler}
            onChangeText={(handler) => handleChangeField('handler', handler)}
            error={getErrorFromField('handler')}
          />
        </div>
      )}

      {formik.values.actionTypeId as number === QuestActionTypeEnum.JoinDiscord && (
        <div className={styles.field}>
          <Typography variant={'text'} size={'md'} component={'span'} weight={'regular'}>{language.discordServerBotInstruction}</Typography>
          <a href='https://discord.com/api/oauth2/authorize?client_id=1148690499524370524&permissions=1024&scope=bot' className={styles.link}>
            <Typography variant={'text'} size={'md'} component={'span'} weight={'regular'}>{language.addBotToServerLinkText}</Typography>
            <RiExternalLinkLine />
          </a>
        </div>
      )}

      {/*
      {formik.values.actionTypeId as number === QuestActionTypeEnum.JoinTelegram && (
        <div className={styles.field}>
          <Typography variant={'text'} size={'md'} component={'span'} weight={'regular'}>{language.discordServerBotInstruction}</Typography>
          <a href='https://discord.com/api/oauth2/authorize?client_id=1148690499524370524&permissions=1024&scope=bot' className={styles.link}>
            <Typography variant={'text'} size={'md'} component={'span'} weight={'regular'}>{language.addBotToServerLinkText}</Typography>
            <RiExternalLinkLine />
          </a>
        </div>
      )}
      */}

      {formik.values.handler !== '' && (formik.values.actionTypeId === QuestActionTypeEnum.TwitterLike || formik.values.actionTypeId === QuestActionTypeEnum.TwitterReply || formik.values.actionTypeId === QuestActionTypeEnum.TwitterRetweet) && (
        <div className={styles.field}>
          <Tweet id={formik.values.handler} />
        </div>
      )}

      <div className={styles.actions}>
        <Button type={'button'} variant={'outline'} color={'primary'} size={'lg'} onClick={closeModal}>{language.cancelButton}</Button>
        <Button type={'submit'} variant={'solid'} color={'primary'} size={'lg'} onClick={handleClick} isLoading={isLoading}>{isEditing ? language.editQuestButton : language.createQuestButton}</Button>
      </div>
    </form>
  );
}

export type {FormValues};

export default CreateOrEditQuestForm;
