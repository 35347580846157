export interface ICollectionType {
  id: number;
  name: string;
  active: boolean;
}

interface CollectionTypes {
  Community: number,
  GiftsEvent: number,
  Fans: number,
  Business: number
};

const collectionsTypeId: CollectionTypes = {
  Community: 1,
  GiftsEvent: 2,
  Fans: 3,
  Business: 4
};

export const getCollectionTypeData = (collectionTypeId: number): ICollectionType | undefined => {
  const collectionTypeKeyId = Object.keys(collectionsTypeId).find(key => collectionsTypeId[key as keyof CollectionTypes] === collectionTypeId);
  if (!collectionTypeKeyId) return undefined;
  return collectionTypesMap[collectionTypeKeyId as keyof CollectionTypes];
};

const collectionTypesMap: Record<keyof CollectionTypes, ICollectionType> = {
  Community: {
    id: 1,
    name: 'Digital passes for a community',
    active: true
  },
  GiftsEvent: {
    id: 2,
    name: 'Digital gifts for an event',
    active: true
  },
  Fans: {
    id: 3,
    name: 'To identify my fans',
    active: true
  },
  Business: {
    id: 4,
    name: 'For my business',
    active: true
  }
};

export const collectionTypes = () => {
  const convertCollectionType = (collectionType: ICollectionType) => ({
    value: collectionType.id,
    label: collectionType.name
  });

  const isActive = true;
  return Object.values(collectionTypesMap).filter(collectionType => (collectionType.active === isActive)).map(convertCollectionType);
};
