// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {EditCollectionAction} from '@store/collection/types';
import {EditCollectionResponse} from '@services/collection/types';
import {LoadersId} from '@type/loaders';
import {ErrorMessage} from '@type/error';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {setCollectionData} from '@store/collection/actions';

// Services
import * as services from '@services/collection';

// Utils
import errorHandler from '@store/errorHandler';

/**
 * Generator function for editing a collection.
 *
 * @function
 * @name editCollectionProcess
 * @param {Object} action - Action object with properties: payload {Object}, collectionId {string}, data {Object}
 * @returns {SagaIterator<void>} A saga iterator representing the edit collection process.
 */
function * editCollectionProcess({payload: {collectionId, data: collection}}: EditCollectionAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_EDITING_COLLECTION, true));

    const formData = yield call(() => {
      const data = new FormData();
      if (collection.banner && collection.banner instanceof File) {
        data.append('banner', collection.banner);
      }

      if (collection.avatar && collection.avatar instanceof File) {
        data.append('logo', collection.avatar);
      }

      data.append('collection', JSON.stringify({
        name: collection.name,
        collectionTypeId: collection.collectionTypeId,
        description: collection.description,
        chainId: collection.chainId,
        ...(!(collection.banner instanceof File) && ({
          banner: collection.banner
        })),
        ...(!(collection.avatar instanceof File) && ({
          smallLogo: collection.avatar
        })),
        public: collection.public,
        givitUrl: collection.givitUrl,
        instagramUrl: collection.instagramUrl,
        openSeaUrl: collection.openSeaUrl,
        discord: collection.discord,
        url: collection.url,
        calendy: collection.calendy,
        twitter: collection.twitter,
        quests: collection.quests
      }));

      return data;
    });

    const response: EditCollectionResponse = yield call(services.editCollection, collectionId, formData);

    if (response) {
      yield put(setCollectionData(collectionId, response));
      yield put(setIsLoading(LoadersId.IS_EDITING_COLLECTION, false));
    }
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_EDITING_COLLECTION, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default editCollectionProcess;
