// Dependencies
import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

// Forms
import NewCollectionForm, {FormValues} from '@forms/NewCollectionForm';

// Hooks
import useCollections from '@hooks/useCollections';
import useLoader, {LoadersId} from '@hooks/useLoader';

function NewCollection(): React.ReactElement | null {
  const {createNewCollection} = useCollections();
  const {isLoading} = useLoader(LoadersId.IS_CREATING_COLLECTION);
  const navigate = useNavigate();

  /**
   * @function handleNewCollection():
   * @description Handle when the user save a collection.
   */
  const handleNewCollection = useCallback(({chain, collectionType, name, description, isPublic, givitUrl, avatar, banner, instagramUrl, url, openSeaUrl, twitter, discord, canCreateQuests}: FormValues) => {
    if (name && description && banner) {
      if (banner instanceof File) {
        createNewCollection({
          chainId: Number(chain?.value),
          collectionTypeId: Number(collectionType?.value),
          name,
          description,
          isPublic,
          avatar,
          banner,
          givitUrl,
          instagramUrl,
          openSeaUrl,
          url,
          twitter,
          discord,
          quests: canCreateQuests
        });
      }
    }
  }, []);

  return (
    <NewCollectionForm
      onSubmit={handleNewCollection}
      onCancel={() => navigate('/creator')}
      isLoading={isLoading}
    />
  );
}

export default NewCollection;
