// Dependencies
import React, {useState, useEffect, useCallback} from 'react';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import TelegramConnectAction from '../TelegramConnectAction';

// StyleSheet
import styles from './TelegramFollowAction.module.scss';

// Types
import {TelegramFollowActionProps} from './types';

// Hooks
import useLoader, {LoadersId} from '@hooks/useLoader';

function TelegramFollowAction({
  avatar,
  creator,
  userToFollow,
  user,
  checkEnabled,
  onAction,
  onCheck,
  onConnection
}: TelegramFollowActionProps): React.ReactElement {
  const {isLoading: isLoadingCompletingQuest} = useLoader(LoadersId.IS_COMPLETING_QUEST);
  const [clickButton, setClickButton] = useState<boolean>(false);

  useEffect(() => {
    setClickButton(checkEnabled);
  }, []);

  const handleClickCheckAction = useCallback(() => {
    setClickButton(true);
    onAction();
  }, []);

  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <img className={styles.photo} width={'42px'} height={'42px'} src={avatar} />
        <div className={styles.title}>
          <Typography variant={'text'} size={'md'} weight={'semiBold'}>{creator}</Typography>
          <Typography variant={'text'} size={'xs'}>{userToFollow}</Typography>
        </div>
        <div className={styles.action}>
          <Button onClick={handleClickCheckAction}>Join Discord</Button>
        </div>
      </div>
      <div className={styles.body}>
        <TelegramConnectAction isLoading={isLoadingCompletingQuest} disabled={!clickButton} onClick={onConnection} />
      </div>
    </div>
  );
}

export default TelegramFollowAction;
