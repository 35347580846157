export interface IRewardType {
  id: number;
  name: string;
}

interface RewardTypes {
  Code: number,
  Link: number,
  Multimedia: number,
  Lottery: number
};

const rewardsTypeId: RewardTypes = {
  Code: 1,
  Link: 2,
  Multimedia: 3,
  Lottery: 4
};

export const getRewardTypeData = (rewardTypeId: number): IRewardType | undefined => {
  const rewardTypeKeyId = Object.keys(rewardsTypeId).find(key => rewardsTypeId[key as keyof RewardTypes] === rewardTypeId);
  if (!rewardTypeKeyId) return undefined;
  return rewardTypesMap[rewardTypeKeyId as keyof RewardTypes];
};

const rewardTypesMap: Record<keyof RewardTypes, IRewardType> = {
  Code: {
    id: 1,
    name: 'Code'
  },
  Link: {
    id: 2,
    name: 'Link'
  },
  Multimedia: {
    id: 3,
    name: 'Multimedia'
  },
  Lottery: {
    id: 4,
    name: 'Lottery'
  }
};

export const rewardTypes = () => {
  const convertRewardType = (rewardType: IRewardType) => ({
    value: rewardType.id,
    label: rewardType.name
  });

  return Object.values(rewardTypesMap).map(convertRewardType);
};
