// Dependencies
import React, {useCallback, useState} from 'react';
import {FormikProps, useFormik} from 'formik';
import classNames from 'clsx';

// StyleSheet
import styles from './CreateOrEditRewardForm.module.scss';

// Components
import Button from '@components/Button';
import TextInput from '@components/TextInput';
import Selector from '@components/Selector';
import DateInput from '@components/DateInput';
import Typography from '@components/Typography';
import RadioInput from '@components/RadioInput';
import ImageUpload from '@components/ImageUpload';
import QuillTextInput from '@components/QuillTextInput';

// Types
import {FormValues, NewRewardFormProps} from './types';

// Validation
import {NewRewardStep1Validation, NewRewardStep2Validation} from './validations';

// Hooks
import useForm from '@hooks/useForm';
import useModal from '@hooks/useModal';

// Types
import {rewardTypes} from '@type/rewardType';

import language_es from 'src/locales/es/forms/createOrEditRewardForm.json';
import language_en from 'src/locales/en/forms/createOrEditRewardForm.json';

const initialDefaultValues: FormValues = {
  image: undefined,
  title: '',
  description: '',
  type: {
    label: 'Code',
    value: 1
  },
  rewardContent: '',
  date: '',
  unique: false
};

export type {FormValues};

export default function CreateOrEditRewardForm({initialValues = initialDefaultValues, isLoading, onSubmit}: NewRewardFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();
  const [step, setStep] = useState(0);
  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: step === 0 ? NewRewardStep1Validation : NewRewardStep2Validation,
    onSubmit
  });

  const {handleChangeField, getErrorFromField} = useForm<FormValues>(formik);

  /**
   * Validates the form using Formik's validateForm method.
   * If no errors are detected, the function increments the step
   * to proceed to the next step.
   */
  const handleNextStep = () => {
    void formik.validateForm().then(errors => {
      if (Object.keys(errors).length === 0) {
        setStep(step + 1);
      }
    });
  };

  /**
   * Callback function to handle dropping photos into a field.
   *
   * @function
   * @name handleDropPhoto
   * @param {string} field - The name of the field where the photo is dropped.
   * @param {File[]} photos - An array of File objects representing the dropped photos.
   * @returns {void}
   */
  const handleDropPhoto = useCallback((field: keyof FormValues, photos: File[]) => {
    if (photos.length === 1) {
      const file = photos[0];
      const reader = new FileReader();

      console.log(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = function(e) {
        console.log('dentro de onload', photos[0]);
        const photo = Object.assign(photos[0], {
          preview: URL.createObjectURL(file)
        });
        handleChangeField(field, photo);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classNames([styles.container, styles.body])}>
        {step === 0 && (
          <div className={styles.grid}>
            <div className={classNames([styles.field, styles.only])}>
              <TextInput
                name={'title'}
                placeholder={language.giftTitleLabel}
                value={formik.values.title}
                error={getErrorFromField('title')}
                onChangeText={(title) => handleChangeField('title', title)}
              />
            </div>

            <div className={classNames([styles.field, styles.only])}>
              <ImageUpload
                label={'Add a picture to preview the reward'}
                onDrop={(photos) => handleDropPhoto('image', photos)}
                photo={formik.values.image}
              />
            </div>

            <div className={classNames([styles.field, styles.only])}>
              <DateInput
                type={'datetime-local'}
                label={language.giftReleaseDateLabel}
                placeholder={''}
                value={formik.values.date}
                onChange={(date) => handleChangeField('date', date)}
                error={getErrorFromField('date')}
              />
            </div>

            <QuillTextInput
              className={styles.description}
              placeholder={language.giftDescriptionPlaceholder}
              theme='snow'
              value={formik.values.description}
              onChange={(value) => handleChangeField('description', value === '<p><br></p>' ? '' : value)}
              error={getErrorFromField('description')}
            />

          </div>
        )}

        {step === 1 && (
          <div className={styles.grid}>
            <div className={classNames([styles.field, styles.only])}>
              <Selector
                placeholder={'Tipos de regalos'}
                options={rewardTypes()}
                value={formik.values.type}
                onChange={(item) => handleChangeField('type', item)}
              />
            </div>

            {formik.values.type?.value === 1 && (
              <>
                <div className={classNames([styles.inline, styles.withoutCenter])}>
                  <RadioInput
                    label={language.sameForAllUsersLabel}
                    description={language.sameForAllUsersDescription}
                    checked={!formik.values.unique}
                    onChange={() => {
                      handleChangeField('unique', false);
                    }}
                  />
                  <RadioInput
                    label={language.uniqueForEachUserLabel}
                    description={language.uniqueForEachUserDescription}
                    checked={formik.values.unique}
                    onChange={() => {
                      handleChangeField('unique', true);
                    }}
                  />
                </div>
                <div>
                  {!formik.values.unique && (
                    <TextInput
                      name={'rewardContent'}
                      placeholder={language.rewardCodePlaceholder}
                      value={formik.values.rewardContent}
                      error={getErrorFromField('rewardContent')}
                      onChangeText={(rewardContent) => handleChangeField('rewardContent', rewardContent)}
                    />
                  )}
                </div>
              </>
            )}

            {(formik.values.type?.value === 2) && (
              <div className={styles.grid}>
                <TextInput
                  name={'rewardContent'}
                  placeholder={language.rewardURLPlaceholder}
                  value={formik.values.rewardContent}
                  error={getErrorFromField('rewardContent')}
                  onChangeText={(rewardContent) => handleChangeField('rewardContent', rewardContent)}
                />
              </div>
            )}

            {(formik.values.type?.value === 3) && (
              <div className={styles.grid}>
                <ImageUpload
                  label={'Add the reward file'}
                  onDrop={(photos) => handleDropPhoto('rewardContent', photos)}
                  photo={formik.values.rewardContent}
                />
              </div>
            )}

            {formik.values.type?.value === 4 && (
              <div>
                {'Coming soon!'}
              </div>
            )}

          </div>
        )}
        <div className={styles.actions}>
          <Typography variant={'text'} weight={'medium'} size={'sm'}>{language.stepIndicator.replace('{{currentStep}}', (step + 1).toString())}</Typography>
          <div className={styles.actions}>
            <Button
              variant="outline"
              color="primary"
              type={'button'}
              onClick={() => (step === 0 ? closeModal() : setStep(0))}
              disabled={isLoading}
            >
              {step === 0 ? language.cancelButton : language.backButton}
            </Button>
            <Button
              variant="solid"
              color="primary"
              type={'button'}
              onClick={() => (step === 0 ? handleNextStep() : formik.handleSubmit())}
              disabled={!formik.isValid || isLoading}
              isLoading={isLoading && step === 1}
            >
              {step === 0 ? language.nextButton : language.saveButton}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
