// Dependencies
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'clsx';
import {useSpring, animated} from 'react-spring';

// StyleSheet
import styles from './UserQuestCard.module.scss';

// Types
import {UserQuestCardProps} from './types';
import {QuestTypeEnum} from '@type/quest';

// Hooks
import useModal, {ModalsId} from '@hooks/useModal';

// Components
import QuillTextInput from '@components/QuillTextInput';
import Typography from '@components/Typography';
import Button from '@components/Button';
import IconButton from '@components/IconButton';
import Badge from '@components/Badge';

// Assets
import {FaCaretRight, FaDiscord, FaInstagram, FaTiktok, FaYoutube, FaTwitch, FaTelegram} from 'react-icons/fa';
import {RiCheckLine} from 'react-icons/ri';
import XIcon from '@assets/icons/x-logo-tw.svg';

import language_es from 'src/locales/es/components/cards/userQuestCard.json';
import language_en from 'src/locales/en/components/cards/userQuestCard.json';

function UserQuestCard({
  collectionName,
  quest,
  status,
  userLogged,
  isMobile,
  onClickLogin
}: UserQuestCardProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {openModal} = useModal();
  const dropDownContainer = useRef<HTMLDivElement>();
  const [isQuestOpen, setQuestOpen] = useState<boolean>(false);

  const [dropDownStyle, dropDownApi] = useSpring(() => ({
    height: 0,
    opacity: 0
  }));

  const handleClickOpenCompleteModal = useCallback(() => {
    openModal(ModalsId.COMPLETE_QUEST, {
      collectionName,
      quest,
      userLogged,
      status,
      checkEnabled: false
    });
  }, []);

  const handleClickSignIn = useCallback(() => {
    onClickLogin();
  }, []);

  const renderIcon = useMemo(() => {
    switch (quest.questActionType.questType?.id) {
      case QuestTypeEnum.Twitter:
        return <XIcon />;

      case QuestTypeEnum.Instagram:
        return <FaInstagram />;

      case QuestTypeEnum.Discord:
        return <FaDiscord />;

      case QuestTypeEnum.TikTok:
        return <FaTiktok />;

      case QuestTypeEnum.YouTube:
        return <FaYoutube />;

      case QuestTypeEnum.Twitch:
        return <FaTwitch />;

      case QuestTypeEnum.Telegram:
        return <FaTelegram />;

      default:
        return null;
    }
  }, [quest]);

  useEffect(() => {
    if (dropDownContainer) {
      dropDownApi.start({
        opacity: isQuestOpen ? 1 : 0,
        height: isQuestOpen ? dropDownContainer.current?.clientHeight : 0
      });
    }
  }, [isQuestOpen, dropDownApi]);

  return (
    <div className={classNames([styles.card, {[styles.open]: isQuestOpen}])}>
      <div className={styles.header}>
        <IconButton
          className={styles.icon}
          onClick={() => setQuestOpen(!isQuestOpen)}
          size={'sm'}
          variant={'ghost'}
          icon={<FaCaretRight />}
        />
        <div className={styles.headTitle}>
          <div className={styles.title}>
            {renderIcon}
            <Typography variant={'text'} size={isMobile ? 'sm' : 'md'}>{quest.title}</Typography>
          </div>
          <div className={styles.actions}>
            {(userLogged && !status) && (<div className={styles.inline}>
              <Button size={'md'} onClick={handleClickOpenCompleteModal}>{language.complete}</Button>
            </div>)}
            {(!userLogged) && (<div className={styles.inline}>
              <Button size={'md'} onClick={handleClickSignIn}>{language.signIn}</Button>
            </div>)}
            {userLogged && status && (
              <Badge
                color={'green'}
                variant={'solid'}
                size={'lg'}
                iconRight={(
                  <RiCheckLine />
                )}
                label={language.completed}
              />
            )}
          </div>
        </div>

      </div>
      <animated.div className={styles.body} style={dropDownStyle}>
        <div ref={ref => { if (ref) dropDownContainer.current = ref; }}>
          <QuillTextInput
            className={styles.description}
            theme='snow'
            value={quest.description}
            readOnly={true}
            modules={{toolbar: false}}
          />
        </div>
      </animated.div>
    </div>
  );
}

export default UserQuestCard;
