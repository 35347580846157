// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {CreateNewCollectionAction} from '@store/collection/types';
import {CreateNewCollectionResponse} from '@services/collection/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/collection';

// Actions
import {setNewCollection} from '@store/collection/actions';
import {setIsLoading} from '@store/ui/loaders/actions';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function createNewCollectionProcess()
 * @description Creating a new collection.
 */
function * createNewCollectionProcess({payload: {chainId, collectionTypeId, name, givitUrl, avatar, banner, description, isPublic, openSeaUrl, instagramUrl, url, twitter, discord, quests}}: CreateNewCollectionAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_CREATING_COLLECTION, true));

    const formData = yield call(() => {
      const data = new FormData();
      if (avatar && avatar instanceof File) {
        data.append('logo', avatar);
      }
      data.append('banner', banner);

      data.append('collection', JSON.stringify({
        chainId,
        collectionTypeId,
        name,
        givitUrl,
        description,
        public: isPublic,
        openSeaUrl,
        logo: avatar,
        instagramUrl,
        url,
        twitter,
        discord,
        quests
      }));

      return data;
    });

    const response: CreateNewCollectionResponse = yield call(services.createNewCollection, formData);

    if (response) {
      yield put(setNewCollection(response));
      yield put(setIsLoading(LoadersId.IS_CREATING_COLLECTION, false));

      yield call(() => {
        window.location.href = `/creator/collection/${response.id}` ?? '/';
      });
    }
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_CREATING_COLLECTION, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default createNewCollectionProcess;
